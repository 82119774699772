@use "cirrus-ui/src/cirrus-ext" as * with (
  $config: (
    extend: (
      colors: (
        extended: (
          "nf-green": (
            "100": #d6f3e8,
            "200": #ade7d1,
            "300": #83dcbb,
            "400": #5ad0a4,
            "500": #31c48d,
            // main
            "600": #279d71,
            "700": #1d7655,
            "800": #144e38,
            "900": #0a271c,
          ),
          "nf-navy": (
            "100": #d3d3d8,
            "200": #a7a7b1,
            "300": #7b7b8a,
            "400": #4f4f63,
            "500": #23233c,
            "600": #1c1c30,
            "700": #151524,
            "800": #0e0e18,
            "900": #07070c,
          ),
          "nf-red": (
            "100": #fdecf0,
            "200": #f8b3c2,
            "300": #f58ea3,
            "400": #f16885,
            "500": #ee4266,
            "600": #be3552,
            "700": #8f283d,
            "800": #5f1a29,
            "900": #18070a,
          ),
          "nf-purple": (
            "100": #eef0ff,
            "200": #bbc1ff,
            "300": #98a3ff,
            "400": #7684ff,
            "500": #5465ff,
            "600": #4351cc,
            "700": #323d99,
            "800": #222866,
            "900": #111433,
          ),
          "nf-yellow": (
            "100": #fdf8ed,
            "200": #f6e1b7,
            "300": #f2d294,
            "400": #edc370,
            "500": #e9b44c,
            "600": #ba903d,
            "700": #8c6c2e,
            "800": #5d481e,
            "900": #2f240f,
          ),
          "nischa-orange": (
            "100": #fde7cf,
            "200": #fbcf9f,
            "300": #f8b76f,
            "400": #fa9d36,
            "500": #f4870f,
            "600": #dc7a0e,
            "700": #ab5f0b,
            "800": #7a4408,
            "900": #311b03,
          ),
        ),
      ),
    ),
  )
);

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");

// p,
// span {
//   font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
//     "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
//     "Segoe UI Emoji", "Segoe UI Symbol";
//   font-optical-sizing: auto;
//   font-style: normal;
// }
